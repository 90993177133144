import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../layout'
import PostListing from '../components/PostListing'
import SEO from '../components/SEO'
import config from '../../data/SiteConfig'
import Carousel from '../components/Carousel'
import JointImages from '../components/JointImages'

const Blog = ({ data }) => (
  <Layout>


    <main>

      <Helmet title={config.siteTitle}>
      
      <link 
	rel="stylesheet"
	type="text/css"
	charset="UTF-8"
	href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
	/>
	<link
	rel="stylesheet"
	type="text/css"
	charset="UTF-8"
	href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
	/>

      </Helmet>
      <SEO />
     
      <span><JointImages /></span> <h1>Troop News</h1>

    
      <PostListing postEdges={data.allMarkdownRemark.edges} />
    </main>
  </Layout>
)

export default Blog

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [fields___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
            categories
          }
        }
      }
    }
  }
`
